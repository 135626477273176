.containerAreaDashboard {
  display: flex;
  flex-direction: row;
}

.contentDashbordDash {
  width: 100%;
  height: 100vh;
  padding: 20px;
}

.areaUsuarios {
  width: 70%;
  margin-bottom: 40px;
}

.cardUsuario {
  width: 100%;
  background-color: #eee;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.cardUsuario p {
  margin-bottom: 5px;
}

.paginacao {
  margin-top: 40px;
  margin-bottom: 20px;
  width: 100%;
}

.areaBotoes {
  display: flex;
  gap: 15;
}

.botaoPaginacao {
  padding: 10px;
}

.boxFotoPerfil {
  gap: 15px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.boxFotoPerfil img {
  width: 64px;
  height: 64px;
  border-radius: 64px;
  object-fit: cover;
}

/* nome do usuário */
.boxFotoPerfil span {
  font-size: 1rem;
  color: #333;
  font-weight: bold;
}

.colCard {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.areaBotoes {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}

.areaBotoes button {
  padding: 15px;
  border: none;
  border-radius: 8px;
  background-color: #4c4949;
  color: white;
  cursor: pointer;
  font-weight: bold;
  font-size: 0.8rem;
}

#activeButton {
  background-color: #af1515;
}

.boxSelectFilter {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.selectFilterDepoimentos {
  padding: 10px;
  border-radius: 6px;
}

.areaDivisaoParticipacoes {
  border: 1px solid #bbb;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.areaDescricaoCaso {
  margin-bottom: 10px;
  margin-top: 10px;
}
.areaDescricaoCaso p {
  font-size: 19px;
}

.fotoMinhasParticipacoes {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.areaFotos {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 15px;
}

.boxFotoPromo p {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 8px;
}

.botaoLerMais {
  padding: 10px;
  background-color: #333;
  color: white;
  border-radius: 6px;
}

.botaoExibirMais {
  padding: 10px;
  background-color: #333;
  color: white;
  border-radius: 6px;
  cursor: pointer;
}

.areaBotoesAprovacoes {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.areaBotoesAprovacoes button {
  background-color: #333;
  color: white;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  border: none;
}

.botaoShowForm {
  background-color: #333;
  color: white;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  margin-top: 15px;
}

.botaoSave {
  background-color: #333;
  color: white;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  margin-bottom: 30px;
}

.buttonAprovar {
  background-color: green !important;
}

.buttonTalvez {
  background-color: blue !important;
}

.buttonReprovar {
  background-color: #af1515 !important;
}

.areaObservacao {
  width: 400px;
  height: 250px;
  margin-top: 20px;
  margin-bottom: 60px;
}

.areaObservacao textarea {
  width: 100%;
  height: 100%;
  font-size: 17px;
  padding: 8px;
}

.areaPesquisar {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 30px;
}

.areaPesquisar input {
  width: 300px;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #333;
}

.areaPesquisar button {
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #bbb;
  cursor: pointer;
  font-size: 17px;
}
