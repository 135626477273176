.containerAreaDashboard {
  display: flex;
  flex-direction: row;
}

.contentDashbordDash {
  width: 100%;
  height: 100vh;
  padding: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-group input {
  border: 1px solid #777;
}

.areaSelect {
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.areaSelect select {
  width: 100%;
  border-radius: 6px;
  padding: 10px;
}

.areaSelect button {
  padding: 10px;
  cursor: pointer;
}

.areaForm h3 {
  padding: 20px;
}

.areaCategoria {
  margin-top: 30px;
}
