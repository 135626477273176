.containerAreaDashboard {
  display: flex;
  flex-direction: row;
}

.contentDashbord {
  width: 100%;
  height: 100vh;
  padding: 20px;
  flex-direction: row;
  display: flex;
}

.coluna1 {
  width: 75%;
}

.rowSegundaLinha {
  margin-top: 30px;
}

.rowSegundaLinha .boxAnaliseBig {
  width: auto;
}

.coluna2 {
  width: 25%;
}
.containerAnalises {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.boxAnalise {
  width: 30%;
  height: 100px;
  padding: 10px;
  border-radius: 8px;
  background-color: rgb(3, 178, 35);
}

.boxAnaliseBig {
  width: 100%;
  max-width: 700px;
  height: auto;
  display: table;
  padding: 10px;
  border-radius: 8px;
}

/* titulo do box */
.boxAnaliseBig,
.boxAnalise p {
  font-size: 14px;
  font-weight: bold;
  color: white;
}

/* Titulo de númeração */

.boxAnalise span {
  font-size: 35px;
  font-weight: bold;
  color: white;
}

.boxAnaliseCenter {
  height: 80%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.boxTitleEIcon {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.boxTitleEIconBig {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}

.boxCidade {
  margin-bottom: 10px;
  border-bottom: 1px solid #fff;
}

.containerAreaEstados {
  flex-wrap: wrap;
  display: flex;
  gap: 15px;
}

.orangeBox {
  background-color: orange;
}

.blueBox {
  background-color: rgba(0, 0, 255, 0.773);
}

.darkBox {
  background-color: #555;
}

.boxItem {
  background-color: #777;
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.boxItemBlueDark {
  background-color: #2f2fdc;
}
