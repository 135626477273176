.headerSorteio {
  width: 100%;
  height: 70px;
  background-color: #121212;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;

  && img {
    width: 200px;
    height: 50px;
    object-fit: contain;
  }

  && a {
    color: white;
  }
}

.areaContainer {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  width: 70%;
  margin: 0 auto;

  && select {
    padding: 15px;
    border-radius: 4px;
  }
}

.areabannerSorteio img {
  width: 600px;
  height: 600px;
  border-radius: 10px;
  object-fit: cover;
}

.areaTextoSucesso {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  margin-top: 20px;

  && h3 {
    color: #c4d30e;
  }
}

.areaSorteio {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  && ul li {
    list-style: none;
    margin-bottom: 30px;
    border-bottom: 1px solid #c4d30e;
    padding-bottom: 10px;
  }
}

.containerBolhas {
  display: flex;
}

.areaBolhas {
  width: 350px;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes moveLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-5%);
  }
}

@keyframes moveRight {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5%);
  }
}

@keyframes moveText {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5%);
  }
  100% {
    transform: translateX(-5%);
  }
}

.ImgBolhaFundo {
  z-index: 10;
  width: 400px;
  animation: moveLeft 5s linear infinite;
}

.ImgBolhaFrente {
  max-width: 400px;
  z-index: 100;
  top: 200px;
  margin-left: 30px;
  position: absolute;
  animation: moveRight 5s linear infinite;
}

.areaNomesSorteio {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  z-index: 150px;
  margin-top: 40px;
  margin-left: 15px;
  color: white;
  z-index: 400;
  animation: moveRight 5s linear infinite;

  && span,
  p {
    color: white;
    font-weight: bold;
    font-size: 30px;
  }
}

.botaoSortear {
  margin-left: 0px;
  font-size: 25px;
  color: #fff;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-weight: 800;
  letter-spacing: 1px;
  z-index: 400;
  animation: moveText 5s linear infinite;
}

.listaGanhadores {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.boxCirculo {
  width: 35px;
  height: 35px;
  border-radius: 35px;
  background-color: #c4d30e;
  color: white;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 16px;
}

.pushable {
  width: 305px;
  height: 305px;
  background: #778108;
  border-radius: 100%;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline-offset: 4px;
}

.pushable span {
  color: #333 !important;
}

.front {
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 12px 42px;
  border-radius: 100%;
  font-size: 1.25rem;
  background: #c4d30e;
  color: #333;
  transform: translateY(-6px);
}

.pushable:active .front {
  transform: translateY(-2px);
}
