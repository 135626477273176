.containerMultiForm {
  padding-left: 20px;
  padding-right: 20px;
}

.containerMultiFormMarginTop {
  margin-top: 30px;
}

.areaHeaderSteps {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.titlePageMultiForm {
  margin-bottom: 10px;
}

.boxInput {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  gap: 5px;
}

.boxInput label {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.boxInput input {
  height: 48px;
  padding: 15px;
  border-radius: 4px;
  font-size: 16px;
  border: 1px solid #a9a9a9;
}

.boxInput select {
  height: 48px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 4px;
  border: 1px solid #a9a9a9;
  background-color: white;
  font-size: 16px;
}

.boxInput textarea {
  height: 200px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #a9a9a9;
  background-color: white;
  font-size: 16px;
}

.areaButtonMultiForm {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-end;
  margin-top: 30px;
}

.buttonMultiForm {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 15px;
  border: none;
  background-color: #60984d;
}
.teste {
  background-color: #60984d;
}
.buttonMultiFormBack {
  border: none;
  background-color: transparent;
  font-size: 18px;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.buttonMultiFormFinalize {
  width: 200px;
  height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  color: white;
  font-size: 22px;
  cursor: pointer;
}

.areaButtonCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.areaButtonCenter a {
  color: #333;
}

@media screen and (min-width: 1024px) {
  .containerMultiForm {
    width: 600px;
    margin: 0 auto;
    margin-top: 40px;
  }
}
